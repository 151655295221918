import React, { useState,useEffect } from "react";
import styles from "./servicemain.module.css";
import { Col, Container, Row } from "react-bootstrap";
import { IoIosArrowRoundForward } from "react-icons/io";
import CaseStudyDialog from "../CaseStudyDialog";
import ContactFormService from "../ContactFormService";
import { useNavigate } from "react-router-dom";
import ServiceKeyPoints from "../ServiceKeyPoints";

const ServiceMainContent = ({ keyPoints, heading, caseStudy }) => {

  const navigate = useNavigate()
  const [modalShow, setModalShow] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);


  useEffect(() => {
    const storedFormSubmitted = localStorage.getItem('formSubmitted');
    setIsFormSubmitted(storedFormSubmitted);
  }, []);


  const handleOpenModal = () => {
    if (isFormSubmitted) {
      navigate(caseStudy?.url)
    } else {
      setModalShow(true);
    }
  };
  

  let caseStudyLength = caseStudy ? Object.keys(caseStudy)?.length : 0;
  
  
  return (
    <>
    {
      !isFormSubmitted &&
      <CaseStudyDialog modalNavigateUrl={caseStudy?.url} modalShow={modalShow} setModalShow={setModalShow} />
    }
      <ServiceKeyPoints heading={heading} keyPoints={keyPoints} />
      {caseStudy && (
        <div className={`p-4 pt-5 ${styles.casestudyContainer} `}>
          <Container>
            <Row>
              <Col md={7}>
                <div>
                  <h2>{caseStudy?.heading}</h2>
                  <p>{caseStudy?.desc}</p>
                  <div
                    onClick={handleOpenModal}
                    className={`pt-md-5 ${styles.singleCarouselCircleSection}`}
                  >
                    <span className={styles.cta}>
                      <IoIosArrowRoundForward
                        color="black"
                        fontWeight="bold"
                        className={styles.singlCarouselRightArrow}
                      />
                      <span>Read full case study</span>
                    </span>
                  </div>
                </div>
              </Col>
              <Col md={5}>
            <div style={{display:"flex",justifyContent:"center",alignItems:"center"}} >
              <img alt={caseStudy?.heading} src={caseStudy?.img} className="mt-md-1" style={{height:"auto",width:"100%",objectFit:"contain"}} />
            </div>
          </Col>
            </Row>
          </Container>
        </div>
      )}
      <ContactFormService caseStudyLength={caseStudyLength} />
    </>
  );
};

export default ServiceMainContent;
