import React from 'react'
import styles from "./homeBannerImageSection.module.css"

const BannerImageSection = ()=> {

  const backgroundImageURL = 'url("/assets/technology1.WebP")'
  return (
    <div style={{ backgroundImage: backgroundImageURL}} className={styles.homeConatiner} >
    <div className={styles.technology} >
    <h1>INNOVATE . CONNECT . INSPIRE</h1>
    <h2>Where technology meets experience</h2>
      <a href="/about-us" className={styles.btn} >Explore More</a>
    </div>
    </div>
  )
}

export default BannerImageSection;