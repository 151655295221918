import React from "react";
import styles from "./serveriKey.module.css";
import { Card, Col, Container, Row } from "react-bootstrap";

const ServiceKeyPoints = ({ heading, keyPoints }) => {
  return (
    <div className="p-4" style={{ background: "black" }}>
      <Container>
        <Row>
          <Col>
            <h2 className="mb-4" style={{ color: "white" }}>
              {heading}
            </h2>
          </Col>
        </Row>
        <Row style={{ display: "flex", justifyContent: "center" }}>
          {keyPoints.map((item) => (
            <Col key={item.id} md={4}>
              <div className={styles.cardContainer}>
                <div className={styles.cardtopborder}></div>
                <Card className={styles.card}>
                  <div className={styles.cardContent}>
                    <h4>{item?.title}</h4>
                    <p dangerouslySetInnerHTML={{ __html: item.desc }}></p>
                  </div>
                </Card>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default ServiceKeyPoints;
