import React from "react";
import styles from "./aboutOverview.module.css";
import { Col, Container, Row } from "react-bootstrap";

const AboutOverviewSection = ()=> {
  return (
    <div className="mt-5 mb-5">
      <Container>
        <Row>
          <Col>
            <h3 className={styles.heading}>Overview</h3>
          </Col>
        </Row>
        <Row>
          <Col md={6} className={styles.overviewFirstSec} >
            <p >
              UNDERSTANDING YOUR BUSINESS IS KEY TO PROVIDING RIGHT SOLUTIONS
              AND THAT IS WHERE WE ARE GOOD AT.
            </p>
          </Col>
          <Col md={6}>
            <p className={styles.overviewdesc}>
              Welcome to Orbio Solutions, where we don't just envision success;
              we make it a reality by transforming your digital, AI, and
              analytics initiatives into impactful achievements. At Orbio, we
              take pride in our commitment to empowering businesses with
              best-in-class products and services, providing the essential tools
              to seamlessly implement, scale, and optimize for sustained
              business growth. Our dedicated team excels in delivering
              comprehensive end-to-end solutions, encompassing software
              expertise, data analytics, digital transformation, automation, and
              innovative digital solutions. Join us on a journey where your
              ideas and aspirations meet our expertise, driving your business
              success and growth.
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default AboutOverviewSection;
