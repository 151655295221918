import React from "react";
import { Button, Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import styles from "./blogCards.module.css";

const BlogCards = ({ obj }) => {
  const navigate = useNavigate();

  return (
    <div>
      <div className={styles.cardContainer}>
        <Card className={styles.card}>
          <div className={styles.imageContainer}>
            <Card.Img
              onClick={() => navigate(obj.url)}
              variant="top"
              src={obj.img}
              style={{ height: "200px", width: "100%", objectFit: "cover" }}
            />
          </div>
          <div className={styles.cardSection}>
            <h6 className={styles.blogtitle} onClick={() => navigate(obj.url)}>
              {obj.title}
            </h6>
            <div className={styles.keywordcontainer}>
              {obj.keywords.map((obj, index) => (
                <Button key={index} className={styles.btn}>
                  {obj}
                </Button>
              ))}
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default BlogCards;
