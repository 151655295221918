import React, { useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import styles from "./youtubesection.module.css";

import VideoPlayerLeftSection from "../VideoPlayerLeftSection";
import YoutubeVideoModal from "../YoutubeVideoModal";

const YoutubeSection = ()=> {
  const [youtubeModalOpen, setYoutubeModalOpen] = useState(false);

  return (
    <div>
      {youtubeModalOpen && (
        <YoutubeVideoModal
          youtubeModalOpen={youtubeModalOpen}
          setYoutubeModalOpen={setYoutubeModalOpen}
        />
      )}
      <Container className="mt-4">
        <Row className="mb-5">
          <Col xs={12}>
            <h3 className={styles.mainheading} >
              <span className={styles.redUnderline}>About</span>
              <span style={{ color: "#007fc7",marginLeft:"5px" }}>Orbio</span>
            </h3>
          </Col>
        </Row>
        <Row className="mb-5">
          <VideoPlayerLeftSection setYoutubeModalOpen={setYoutubeModalOpen} />
          <Col
            md={7}
            style={{ display: "flex", flexDirection: "column", gap: "16px" }}
          >
            <div>
              <Card className={`${styles.singlecard}`}>
                <Card.Body>
                  <p className={styles.cardcontent}>
                    Orbio Solutions is a technology and consulting leader that
                    combines design, data and technology to revolutionize your
                    business. Our track record in strategizing, sourcing, and
                    creating top-tier enterprise IT solutions drives your
                    implementation and business expansion forward.We bring
                    significant expertise across latest technologies combined
                    with our success in serving the needs of customers in a
                    variety of industry verticals.
                  </p>
                </Card.Body>
              </Card>
            </div>
            <div>
              <Card className={`${styles.singlecard}`}>
                <Card.Body>
                  <p className={styles.cardcontent}>
                    Our motto is to empower you to scale your business, undergo
                    transformative changes, and secure a competitive advantage
                    through robust and adaptable solutions. Through our agile
                    development methodologies and collaborative approach, we
                    become an extension of your team, working hand-in-hand to
                    bridge the gap between your vision and reality. With Orbio
                    Solutions, you benefit from a trusted advisor and
                    implementation expert to co-pilot your journey to
                    transformative growth.
                  </p>
                </Card.Body>
              </Card>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default YoutubeSection;
