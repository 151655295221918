import React from "react";
import styles from "./generative.module.css";
import { Helmet } from "react-helmet-async";
import CommonBannerImage from "../../components/CommonBannerImage";
import { Card, Col, Container, Image, Row } from "react-bootstrap";
import { LiaIndustrySolid } from "react-icons/lia";
import { FaUsers } from "react-icons/fa";
import { GrCloudComputer } from "react-icons/gr";
import { MdOutlineSupportAgent } from "react-icons/md";
import ContactFormService from "../../components/ContactFormService";
import { Link } from "react-router-dom";

const GenerativeAi = () => {
  const serviceSchema = {
    "@context": "https://schema.org",
    "@type": "Service",
    serviceType: "Generative AI Service",
    provider: {
      "@type": "Organization",
      name: "Orbio Solutions",
      url: "https://www.orbiosolutions.com",
    },
    description:
      "Transformative generative AI services designed to enhance business efficiency and innovation. Harness advanced technology for impactful solutions.",
    offers: {
      "@type": "Service",
      url: "https://www.orbiosolutions.com/generative-ai-service",
    },
  };

  const keyPoints = [
    {
      id: "1",
      title: "Customer Experience Navigator",
      desc: "Leverage AI-powered conversational assistants to provide seamless customer interactions and personalized support. Our solutions enhance engagement, automate processes, and drive customer satisfaction, ensuring a superior customer experience.",
    },
    {
      id: "2",
      title: "Enterprise Knowledge Navigator",
      desc: "Utilize generative AI for advanced knowledge management and semantic search capabilities. Unlock valuable insights, accelerate information discovery, and enhance decision-making with our comprehensive solutions designed to streamline knowledge workflows.",
    },
    {
      id: "3",
      title: "Process Optimizer",
      desc: "Optimize your operations with AI-driven automation. Our Process Optimizer transforms workflows, reduces manual efforts, and achieves process excellence. Enhance productivity and operational efficiency by integrating AI into your business processes.",
    },
    {
      id: "4",
      title: "Development Lifecycle Navigator",
      desc: "Boost developer productivity with AI-driven tools and resources. Our solutions enable faster development cycles, improve code quality, and streamline workflows, fostering innovation and enhancing collaboration across your development teams.",
    },
    {
      id: "5",
      title: "Data Engineering Navigator",
      desc: "Streamline data pipelines and receive recommendations for best practices with our automated solutions. Overcome common data engineering challenges to enhance efficiency and accuracy, ensuring your data processes are optimized for success.",
    },
  ];

  return (
    <>
      <Helmet>
        <title>Generative AI Services: Transforming Ideas into Reality</title>
        <meta
          name="description"
          content="Explore transformative generative AI services designed to enhance business efficiency and innovation. Harness advanced technology for impactful solutions."
        />
        <meta
          property="og:title"
          content="Top AI Integration Services for Seamless Business Solutions"
        />
        <meta
          property="og:description"
          content="Explore Orbio Solutions' AI integration services to revolutionize your business operations. Harness advanced technology for enhanced efficiency and innovation."
        />
        <script type="application/ld+json">
          {JSON.stringify(serviceSchema)}
        </script>
      </Helmet>
      <CommonBannerImage backgroundImageURL="/assets/data-engineering-service.WebP">
        <div className={styles.overlay}></div>
        <div className={styles.textContainer}>
          <h1>Generative AI Service</h1>
          <h2>Embrace the Future with Generative AI Services</h2>
        </div>
      </CommonBannerImage>
      <div className="mt-4">
        <Container>
          <Row className="d-flex justify-content-center align-items-center">
            <Col md={6} className={styles.anlyseContainer}>
              <p>
                Our <Link to="/data-ai">Generative AI Services</Link> are
                revolutionizing the landscape of artificial intelligence,
                opening new frontiers of innovation and collaboration. This
                cutting-edge technology catalyzes digital transformation,
                elevates business operations, and enhances the synergy between
                humans and machines. At Orbio Solutions, we harness the power of
                Generative AI to drive progress, foster innovation, and unlock
                unprecedented possibilities. Our commitment to responsible AI
                ensures that this technology not only benefits businesses but
                also improves everyday life.
              </p>
            </Col>
            <Col md={6}>
              <Image
                src="assets/robotic-process-screen.WebP"
                alt="robotic-process"
                style={{ width: "100%", objectFit: "contain" }}
              />
            </Col>
          </Row>
        </Container>
      </div>

      <div className="mt-5" style={{ background: "#f5f5f5" }}>
        <Container>
          <h2 className="pt-3 pt-md-5 text-center">
            Our Generative AI Services
          </h2>
          <Row className="d-flex justify-content-center p-0 pt-md-3 px-md-5 py-md-5 pb-md-2">
            {keyPoints.map((item) => (
              <Col key={item.id} md={6} className="p-3 p-md-3">
                <h3 style={{fontSize: "20px"}} className="fw-bold">{item.title}</h3>
                <p dangerouslySetInnerHTML={{ __html: item.desc }}></p>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
      <div className="mt-5">
        <Container>
          <h2>Partner with Orbio Solutions</h2>
          <p>
            Our full-scale consulting, advisory, and delivery capabilities are
            backed by extensive client and industry acumen. We apply this
            expertise to help businesses solve their most significant
            challenges, providing tailored AI solutions that drive results.
          </p>
          <Row>
            <Col md={6} className="px-0">
              <Image
                style={{ width: "100%" }}
                alt="ai-strategy"
                src="assets/ai-strategy.WebP"
              />
            </Col>
            <Col
              md={6}
              className="d-flex flex-column justify-content-center px-3 py-3 py-md-0 px-md-5"
            >
              <h3>AI Strategy and Consulting</h3>
              <p>
                At Orbio Solutions, we offer comprehensive AI strategy and
                consulting services. Our experts work closely with your team to
                develop a robust AI roadmap that aligns with your business
                objectives. We ensure that your AI initiatives are strategically
                planned and effectively executed.
              </p>
            </Col>
          </Row>
          <Row>
            <Col
              md={6}
              className="d-flex flex-column justify-content-center px-3 py-3 py-md-0 px-md-5"
            >
              <h3>Custom AI Development</h3>
              <p>
                Develop tailored AI models to meet your specific business needs
                with our custom AI development services. We take a personalized
                approach to AI-driven solutions, creating models to address your
                unique challenges and opportunities.
              </p>
            </Col>
            <Col md={6} className="px-0">
              <Image
                style={{ width: "100%" }}
                alt="ai-development"
                src="assets/ai-development.WebP"
              />
            </Col>
          </Row>
          <Row>
            <Col md={6} className="px-0 order-2 order-md-1">
              <Image
                style={{ width: "100%" }}
                alt="data-analytics"
                src="assets/data-analytics-screen.WebP"
              />
            </Col>
            <Col
              md={6}
              className="d-flex flex-column justify-content-center px-3 py-3 py-md-0 px-md-5 order-1 order-md-2"
            >
              <h3>AI Integration Services</h3>
              <p>
                Seamlessly integrate AI into your existing systems and processes
                with our AI integration services. We ensure that your AI
                solutions are effectively integrated, enhancing functionality
                and driving innovation across your organization.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="mt-5">
        <Container>
          <h2>Our Methodology</h2>
          <Row className="d-flex flex-wrap">
            <Col md={4} className="mt-2 mt-md-0">
              <Card
                style={{
                  width: "100%",
                  height: "100%",
                  border: "none",
                  boxShadow:
                    "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
                }}
              >
                <Card.Body
                  className="p-4"
                  style={{
                    background: "#b2b3b6",
                    color: "black",
                    borderRadius: "7px",
                  }}
                >
                  <Card.Title className="fw-bold">
                    Smooth AI Integration
                  </Card.Title>
                  <Card.Text>
                    Integrate AI effortlessly with our solutions that blend
                    human expertise and advanced technology. We ensure a smooth
                    incorporation into your existing operations, achieving
                    results with minimal disruption.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4} className="mt-2 mt-md-0">
              <Card
                style={{
                  width: "100%",
                  height: "100%",
                  border: "none",
                  boxShadow:
                    "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
                }}
              >
                <Card.Body
                  className="p-4"
                  style={{
                    background: "#007fc7",
                    color: "whitesmoke",
                    borderRadius: "7px",
                  }}
                >
                  <Card.Title className="fw-bold">
                    Ethical AI Practices
                  </Card.Title>
                  <Card.Text>
                    Orbio Solutions prioritizes ethical AI. Our commitment to
                    responsible AI practices means our solutions are innovative,
                    sustainable, and adhere to the highest ethical standards.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4} className="mt-2 mt-md-0">
              <Card
                style={{
                  width: "100%",
                  height: "100%",
                  border: "none",
                  boxShadow:
                    "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
                }}
              >
                <Card.Body
                  className="p-4"
                  style={{
                    background: "#b2b3b6",
                    color: "black",
                    borderRadius: "7px",
                  }}
                >
                  <Card.Title className="fw-bold">
                    Effective Coordination
                  </Card.Title>
                  <Card.Text>
                    Leverage our proficiency in managing complex AI projects. We
                    ensure your AI transformations are executed effectively,
                    utilizing our robust platforms and ecosystems for timely and
                    successful delivery.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row className="mt-md-3">
            <Col md={4} className="mt-2 mt-md-0">
              {" "}
              <Card
                style={{
                  width: "100%",
                  height: "100%",
                  border: "none",
                  boxShadow:
                    "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
                }}
              >
                <Card.Body
                  className="p-4"
                  style={{
                    background: "#007fc7",
                    color: "whitesmoke",
                    borderRadius: "7px",
                  }}
                >
                  <Card.Title className="fw-bold">
                    Specialized Knowledge
                  </Card.Title>
                  <Card.Text>
                    Benefit from our extensive industry knowledge and deep
                    domain expertise. Our tailored solutions are built on a
                    foundation of flexible, reusable platforms that meet your
                    specific business needs and challenges.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4} className="mt-2 mt-md-0">
              <Card
                style={{
                  width: "100%",
                  height: "100%",
                  border: "none",
                  boxShadow:
                    "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
                }}
              >
                <Card.Body
                  className="p-4"
                  style={{
                    background: "#b2b3b6",
                    color: "black",
                    borderRadius: "7px",
                  }}
                >
                  <Card.Title className="fw-bold">
                    Innovation-Driven Approach
                  </Card.Title>
                  <Card.Text>
                    Stay ahead with our focus on continuous innovation. We keep
                    up with the latest{" "}
                    <a
                      href="https://en.wikipedia.org/wiki/Artificial_intelligence"
                      target="_blank"
                      rel="noreferrer"
                    >
                      AI
                    </a>{" "}
                    advancements to provide you with state-of-the-art solutions
                    that drive your business forward.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4} className="mt-2 mt-md-0">
              <Card
                style={{
                  width: "100%",
                  height: "100%",
                  border: "none",
                  boxShadow:
                    "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
                }}
              >
                <Card.Body
                  className="p-4"
                  style={{
                    background: "#007fc7",
                    color: "whitesmoke",
                    borderRadius: "7px",
                  }}
                >
                  <Card.Title className="fw-bold">Data Security</Card.Title>
                  <Card.Text>
                    Protecting your data is our top priority. Our AI solutions
                    incorporate advanced security measures to safeguard your
                    information and ensure compliance with all relevant
                    regulations.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="mt-5">
        <Container>
          <h2>Why Choose Orbio Solutions for Your AI Needs?</h2>
          <Row className="mt-4">
            <Col md={6} className="mb-3">
              <Card
                className="h-100 border-0 p-4"
                style={{
                  boxShadow:
                    "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
                }}
              >
                <Card.Body>
                  <div className="d-flex align-items-center mb-1">
                    <LiaIndustrySolid style={{ fontSize: "30px" }} />
                    <Card.Title className="mb-0 px-1">
                      <h3 style={{ fontSize: "20px" }}>
                        Expansive Industry Knowledge
                      </h3>
                    </Card.Title>
                  </div>
                  <Card.Text>
                    Our extensive experience across various industries allows us
                    to apply best practices and innovative solutions to your
                    unique business challenges. We understand the intricacies of
                    different sectors and tailor our services to meet your
                    specific needs.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={6} className="mb-3">
              <Card
                className="h-100 border-0 p-4"
                style={{
                  boxShadow:
                    "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
                }}
              >
                <Card.Body>
                  <div className="d-flex align-items-center mb-1">
                    <FaUsers style={{ fontSize: "25px" }} />
                    <Card.Title className="mb-0 px-1">
                      <h3 style={{ fontSize: "20px" }}>
                        Client-Centric Approach
                      </h3>
                    </Card.Title>
                  </div>
                  <Card.Text>
                    At Orbio Solutions, we put our clients first. Our
                    client-centric approach ensures that we understand your
                    goals and work collaboratively to achieve them. We are
                    dedicated to delivering solutions that drive value and
                    exceed your expectations.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col md={6} className="mb-3">
              <Card
                className="h-100 border-0 p-4"
                style={{
                  boxShadow:
                    "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
                }}
              >
                <Card.Body>
                  <div className="d-flex align-items-center mb-1 ">
                    <GrCloudComputer style={{ fontSize: "25px" }} />
                    <Card.Title className="mb-0 px-1">
                      <h3 style={{ fontSize: "20px" }}>
                        Cutting-Edge Technology
                      </h3>
                    </Card.Title>
                  </div>
                  <Card.Text>
                    Stay ahead of the curve with our cutting-edge AI technology.
                    We continuously innovate and evolve our solutions to ensure
                    that you benefit from the latest advancements in AI and
                    technology.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={6} className="mb-3">
              <Card
                className="h-100 border-0 p-4"
                style={{
                  boxShadow:
                    "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
                }}
              >
                <Card.Body>
                  <div className="d-flex align-items-center mb-1 ">
                    <MdOutlineSupportAgent style={{ fontSize: "25px" }} />
                    <Card.Title className="mb-0 px-1">
                      <h3 style={{ fontSize: "20px" }}>
                        Comprehensive Support
                      </h3>
                    </Card.Title>
                  </div>
                  <Card.Text>
                    From initial consultation to implementation and beyond, we
                    provide comprehensive support to ensure the success of your
                    AI initiatives. Our team is with you every step of the way,
                    providing guidance, expertise, and support to ensure your
                    projects succeed.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="mt-5">
        <Container>
          <h2>AI Models We Excel In</h2>
          <p>
            At Orbio Solutions, we excel in deploying a variety of sophisticated
            AI models, each crafted to address diverse business requirements:
          </p>
          <div className="d-flex gap-4 flex-wrap justify-content-center mt-4">
            <Card
              style={{
                width: "8em",
                position: "relative",
                height: "110px",
                boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
              }}
              className="d-flex align-items-center p-2"
            >
              <Card.Img
                variant="top"
                src="assets/aiLogo/gptLogo.png"
                alt="gptLogo"
                style={{
                  height: "30px",
                  width: "30px",
                  objectFit: "contain",
                  marginTop: "1.2em",
                }}
              />
              <Card.Body
                className="p-0 pt-1"
                style={{ position: "absolute", bottom: "12px", width: "100%" }}
              >
                <Card.Title
                  className="mb-0 text-center"
                  style={{ fontSize: "14px" }}
                >
                  GPT-4
                </Card.Title>
              </Card.Body>
            </Card>
            <Card
              style={{
                width: "8em",
                position: "relative",
                height: "110px",
                boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
              }}
              className="d-flex align-items-center p-2"
            >
              <Card.Img
                variant="top"
                src="assets/aiLogo/geminiLogo.png"
                alt="geminiLogo"
                style={{ height: "60px", width: "60px", objectFit: "contain" }}
              />
              <Card.Body
                className="p-0 pt-1"
                style={{ position: "absolute", bottom: "12px", width: "100%" }}
              >
                <Card.Title
                  className="mb-0 text-center"
                  style={{ fontSize: "14px" }}
                >
                  Gemini
                </Card.Title>
              </Card.Body>
            </Card>
            <Card
              style={{
                width: "8em",
                position: "relative",
                height: "110px",
                boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
              }}
              className="d-flex align-items-center p-2"
            >
              <Card.Img
                variant="top"
                src="assets/aiLogo/dallELogo.png"
                alt="dallELogo"
                style={{ height: "60px", width: "60px", objectFit: "contain" }}
              />
              <Card.Body
                className="p-0 pt-1"
                style={{ position: "absolute", bottom: "12px", width: "100%" }}
              >
                <Card.Title
                  className="mb-0 text-center"
                  style={{ fontSize: "14px" }}
                >
                  DALL-E
                </Card.Title>
              </Card.Body>
            </Card>
            <Card
              style={{
                width: "8em",
                position: "relative",
                height: "110px",
                boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
              }}
              className="d-flex align-items-center p-2"
            >
              <Card.Img
                variant="top"
                src="assets/aiLogo/palm2.WebP"
                alt="palmLogo"
                style={{ height: "60px", width: "60px", objectFit: "contain" }}
              />
              <Card.Body
                className="p-0 pt-1"
                style={{ position: "absolute", bottom: "12px", width: "100%" }}
              >
                <Card.Title
                  className="mb-0 text-center"
                  style={{ fontSize: "14px" }}
                >
                  PaLM 2
                </Card.Title>
              </Card.Body>
            </Card>
            <Card
              style={{
                width: "8em",
                position: "relative",
                height: "110px",
                boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
              }}
              className="d-flex align-items-center p-2"
            >
              <Card.Img
                variant="top"
                src="assets/aiLogo/gptLogo.png"
                alt="phiLogo"
                style={{
                  height: "30px",
                  width: "30px",
                  objectFit: "contain",
                  marginTop: "1.2em",
                }}
              />
              <Card.Body
                className="p-0 pt-1"
                style={{ position: "absolute", bottom: "12px", width: "100%" }}
              >
                <Card.Title
                  className="mb-0 text-center"
                  style={{ fontSize: "14px" }}
                >
                  Whisper
                </Card.Title>
              </Card.Body>
            </Card>
          </div>
        </Container>
      </div>
      <div className="mt-5">
        <Container>
          <h2>Contact Us</h2>
          <p>
            Ready to transform your business with Generative AI? Contact us
            today to learn more about our Generative AI services and how we can
            help you leverage AI to drive innovation, efficiency, and growth.
            Our team of experts is ready to partner with you on your AI journey.
          </p>
        </Container>
        <ContactFormService caseStudyLength={1} />
      </div>
    </>
  );
};

export default GenerativeAi;
