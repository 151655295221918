import React from "react";
import AboutSlider from "../../components/AboutSlider";
import MeasurableSuccess from "../../components/MeasurableSuccess";
import ServiceListing from "../../components/ServiceListing";
import AchievementSection from "../../components/AchievementSection";
import ReviewSection from "../../components/ReviewSection";
import BannerImageSection from "../../components/BannerImageSection";
import YoutubeSection from "../../components/YoutubeSection";
import { Helmet } from "react-helmet-async";

function HomePage() {
  return (
    <div>
      <Helmet>
        <meta
          name="keywords"
          content="A digital transformation service company"
        />
      </Helmet>
      <BannerImageSection />
      <YoutubeSection />
      <AboutSlider />
      <MeasurableSuccess />
      <ServiceListing />
      <AchievementSection />
      <ReviewSection />
    </div>
  );
}

export default HomePage;
