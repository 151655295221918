import React from "react";
import CommonBannerImage from "../../components/CommonBannerImage";
import { Col, Container, Image, Row } from "react-bootstrap";
import styles from "./technologyConsulting.module.css";
import ServiceMainContent from "../../components/ServiceMainContent";
import { Helmet } from "react-helmet-async";

const TechnologyConsulting = () => {
  const keyPoints = [
    { id: "1", title: "Needs Assessment", desc: "Thoroughly assessing client needs to plan and customize solutions." },
    { id: "2", title: "Requirement Gathering", desc: "Collecting detailed requirements to ensure solutions align with business goals." },
    { id: "3", title: "Strategy Roadmap Creation", desc: "Developing strategy roadmaps to guide successful technology implementation." },
    { id: "4", title: "Solution Design and Recommendation", desc: "Crafting and recommending bespoke technology solutions to meet specific business challenges." },
    { id: "5", title: "Assist with Implementation", desc: "Providing hands-on assistance to ensure smooth and effective technology solution implementation." },
    { id: "6", title: "Technical Support", desc: "Offering robust technical support to maintain solution performance and resolve issues promptly." },
    { id: "7", title: "Stakeholder Communication", desc: "Ensuring transparent and effective communication with stakeholders throughout the consulting process." },
  ];
  return (
    <>
    <Helmet>
        <meta
          name="keywords"
          content="IT technology consulting company"
        />
      </Helmet>
      <CommonBannerImage backgroundImageURL="/assets/technologyconsulting-bg.WebP">
      <div className={styles.overlay}></div>
        <div className={styles.textContainer}>
          <h1>Technology Consulting</h1>
          <h6>Where innovation meets technology</h6>
        </div>
      </CommonBannerImage>
      <div>
        <Container>
          <Row>
            <Col md={6} className={styles.anlyseContainer}>
              <p>
                Empowering businesses through strategic technology consulting
                services Orbio Solutions is a leading enterprise IT consulting
                and service provider Our proven team of consultants in
                technology strategy and implementation are dedicated to ensuring
                your investments are justified with a wide partner network
                branching across a gamut of enterprise IT functions, we explore
                the best ways to accelerate your digital transformation journey.
                The driving force behind our success lies in the relevance of
                our products and services made available to our customers.
              </p>
            </Col>
            <Col md={6} className={`${styles.anlyseImgContainer} d-flex justify-content-center align-items-center`}>
              <Image
                alt="technology"
                src="/assets/technology-screen2.WebP"
                fluid
                className={styles.screenImg}
              />
            </Col>
          </Row>
        </Container>
      </div>

      <ServiceMainContent keyPoints={keyPoints} heading="How we do it:" />
    </>
  );
};

export default TechnologyConsulting;
