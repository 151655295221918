import React from "react";
import styles from "./dataAi.module.css";
import { Helmet } from "react-helmet-async";
import CommonBannerImage from "../../components/CommonBannerImage";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import BlogCards from "../../components/BlogCards";
import ContactFormService from "../../components/ContactFormService";

const DataAiSolution = () => {
  const navigate = useNavigate();

  const AllBlogs = [
    {
      id: "0",
      title: "Big Data Helping Enterprises",
      img: "/assets/Big-Data.WebP",
      url: "/blog/plan-your-project-with-your-software",
      keywords: [
        "Business Intelligence",
        "Cloud Computing",
        "Customer Insights",
        "Data Analytics",
      ],
    },
    {
      id: "2",
      title: "Data-Driven Decision Making",
      img: "/assets/datadriven.WebP",
      url: "/blog/data-driven-decision-making",
      keywords: ["Analyzing", "Efficiency", "Leadership", "Innovation"],
    },
  ];

  return (
    <>
      <Helmet>
        <title>
          Boost Your Business with AI & Data Solutions | Orbio Solutions
        </title>
        <meta
          name="description"
          content="Discover how Orbio Solutions leverages data and artificial intelligence to enhance your business operations, drive innovation, and deliver insightful solutions."
        />
        <meta
          property="og:title"
          content="Revolutionize Your Business with Advanced AI & Data Services"
        />
        <meta
          property="og:description"
          content="Improve your business capabilities with Orbio Solutions. Our AI and data services, including Generative AI, provide the insights for smarter decision-making."
        />
      </Helmet>
      <CommonBannerImage backgroundImageURL="/assets/robotic-process-automation-bg.WebP">
        <div className={styles.overlay}></div>
        <div className={styles.textContainer}>
          <h1>
            Transform Your Business with Comprehensive AI and Data Solutions
          </h1>
        </div>
      </CommonBannerImage>
      <div className="mt-5">
        <Container>
          <div className={styles.anlyseContainer}>
            <p>
              In the digital age, businesses are increasingly leveraging the
              power of Artificial Intelligence (AI) and data analytics to stay
              competitive. At Orbio Solutions, we offer a full spectrum of AI
              and data services designed to transform your business operations,
              drive innovation, and enhance efficiency. Our comprehensive
              approach ensures that your organization can harness the full
              potential of advanced technologies to achieve sustainable growth
              and success.
            </p>
          </div>
        </Container>
      </div>
      <div className="mt-5">
        <Container>
          <Row className="d-flex justify-content-center align-items-center">
            <Col md={6}>
              <h2 style={{ fontSize: "20px" }} className="fw-bold">
                Generative AI Services
              </h2>
              <p>
                Generative AI is revolutionizing industries by automating
                content creation, enhancing designs, and streamlining
                operations. At Orbio Solutions, we harness this advanced
                technology to drive innovation and efficiency. Our expertise in
                Generative AI enables us to develop solutions that boost
                creativity and optimize processes, creating seamless
                interactions between humans and machines. Whether improving
                customer experiences or automating workflows, we provide
                practical, impactful AI-driven solutions tailored to your needs.
                Discover how Generative AI can transform your business with
                Orbio Solutions—where cutting-edge technology meets industry
                expertise. Ready to explore how Generative AI can transform your
                business?
              </p>
              <Button
                className="mb-2 mb-md-0"
                style={{ background: "#007fc7" }}
                onClick={() => navigate("/generative-ai-service")}
              >
                Learn more
              </Button>
            </Col>
            <Col md={6}>
              <Image
                src="assets/robotic-process-screen.WebP"
                alt="robotic-process"
                style={{ width: "100%", objectFit: "contain" }}
              />
            </Col>
          </Row>
          <Row className="d-flex justify-content-center align-items-center mt-5">
            <Col md={6} className="order-2 order-md-1">
              <Image
                src="assets/ai-screen.WebP"
                alt="ai-screen"
                style={{ width: "100%", objectFit: "contain" }}
              />
            </Col>
            <Col md={6} className="order-1 order-md-2">
              <h2 style={{ fontSize: "20px" }} className="fw-bold">
                Data and Artificial Intelligence Services
              </h2>
              <p>
                Enhance your business performance with a data-driven culture,
                leveraging cutting-edge AI technology and insights. At Orbio
                Solutions, we turn raw data into actionable intelligence,
                driving strategic decision-making and operational excellence.
                Our expertise includes developing robust data strategies,
                implementing predictive analytics, ensuring data quality, and
                creating compelling visualizations.By uncovering hidden patterns
                and predicting future trends, we provide a competitive edge
                tailored to your unique needs. Our team collaborates closely
                with you to align solutions with your goals, empowering your
                organization to achieve improved efficiency, innovation, and
                growth. Embrace the transformative power of data and AI with
                Orbio Solutions.
              </p>
              <Button
                className="mb-2 mb-md-0"
                style={{ background: "#007fc7" }}
                onClick={() => navigate("/data-artificial-intelligence")}
              >
                Learn more
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
      <div className={styles.imgDiv}>
        <div className={styles.imgoverlay}></div>
        <div className="h-100 d-flex align-items-center">
          <h3 className={styles.imgContent}>
            Unlock transformative business value with Orbio Solutions'
            integrated Generative AI and Data Services, seamlessly driving
            innovation and operational excellence.
          </h3>
        </div>
      </div>
      <div className="mt-4 mb-4">
        <Container>
          <h5 className="fw-bold">Blogs</h5>
          <Row className="px-5 py-5 pt-0 pb-0">
            {AllBlogs?.map((obj) => (
              <Col md={6} key={obj.id}>
                <BlogCards obj={obj} />
              </Col>
            ))}
          </Row>
        </Container>
      </div>
      <ContactFormService caseStudyLength={1} />
    </>
  );
};

export default DataAiSolution;
