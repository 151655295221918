import React from "react";
import CommonBannerImage from "../../components/CommonBannerImage";
import { Col, Container, Image, Row } from "react-bootstrap";
import styles from "./cloudManagedServices.module.css";
import ServiceMainContent from "../../components/ServiceMainContent";
import { Helmet } from "react-helmet-async";

const CloudManagedServices = () => {
  const keyPoints = [
    { id: "1", title: "Evaluate Current Infrastructure", desc: "Assessing existing infrastructure to determine the most effective cloud transition strategy." },
    { id: "2", title: "Cloud Strategy Development", desc: "Creating a cloud strategy tailored to meet specific business objectives." },
    { id: "3", title: "Cloud Selection and Design", desc: "Selecting and designing the ideal cloud environment to fit business needs." },
    { id: "4", title: "Migration Planning and Execution", desc: "Strategically planning and executing cloud migration for seamless transition and minimal disruption." },
    { id: "5", title: "Implementation of Cloud Management Tools", desc: "Implementing advanced cloud management tools for efficient cloud operations." },
    { id: "6", title: "Performance Monitoring and Optimization", desc: "Monitoring cloud performance continuously for optimization and enhanced efficiency." },
    { id: "7", title: "Cost Management and Optimization", desc: "Managing and optimizing cloud costs for economical and efficient cloud operations." },
    { id: "8", title: "User Training and Change Management", desc: "Providing user training and managing change to ensure smoother cloud adoption." },
    { id: "9", title: "Continuous Improvement and Adaptation", desc: "Continuously improving and adapting cloud solutions to meet evolving business demands." },
  ];
  return (
    <>
    <Helmet>
        <meta
          name="keywords"
          content="Managed cloud service provider and consulting company"
        />
      </Helmet>
      <CommonBannerImage backgroundImageURL="/assets/cloudmanaged-bg.WebP">
      <div className={styles.overlay}></div>
        <div className={styles.textContainer}>
          <h1>Cloud Managed Services</h1>
          <h6>
            Enhance your cloud strategy with our expert cloud managed services
          </h6>
        </div>
      </CommonBannerImage>
      <div className="mt-4">
        <Container>
          <Row>
            <Col md={6} className={styles.anlyseContainer}>
              <p>
                Transitioning your business to the cloud Moving to the cloud is
                no more a luxury. It becomes a necessity as companies look to
                benefit from the advantages of scalability, reduced costs,
                increased collaboration, increased flexibility and more. From
                SMEs to MNCs, cloud application development has become the
                driving force of business continuity. We specialize in fully
                managed cloud services, including hosting, maintenance, cloud
                app development and migration. A growing partner network of
                cloud service providers gives us an edge in offering cloud
                computing and infrastructure management solutions. For
                businesses looking to improve their existing cloud architecture,
                our expert engineers and analysts give you a long-term plan that
                is practical, feasible and profitable.
              </p>
            </Col>
            <Col md={6} className={`${styles.anlyseImgContainer} d-flex justify-content-center align-items-center`}>
              <Image
                alt="img"
                src="/assets/cloud-managed-screen.WebP"
                fluid
                style={{ objectFit: "contain", height: "450px", width: "100%" }}
              />
            </Col>
          </Row>
        </Container>
      </div>

      <ServiceMainContent keyPoints={keyPoints} heading="How we do it:" />
    </>
  );
};

export default CloudManagedServices;
