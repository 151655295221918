import React from "react";
import CommonBannerImage from "../../components/CommonBannerImage";
import { Col, Container, Image, Row } from "react-bootstrap";
import styles from "./qualityEngineering.module.css";
import ServiceMainContent from "../../components/ServiceMainContent";
import { Helmet } from "react-helmet-async";

const QualityEngineering = () => {
  const keyPoints = [
    {
      id: "1",
      title: "Quality Planning",
      desc: "Strategically quality assurance planning to ensure superior software performance and reliability.",
    },
    {
      id: "2",
      title: "Design and Development Integration",
      desc: "Integrating quality engineering principles seamlessly into the design and development phases.",
    },
    {
      id: "3",
      title: "Setting Up Test Environments",
      desc: "Establishing robust test environments for comprehensive quality testing.",
    },
    {
      id: "4",
      title: "Continuous Integration and Testing",
      desc: "Implementing continuous integration and testing for robust quality assurance and improvement.",
    },
    {
      id: "5",
      title: "Defect Management",
      desc: "Efficiently managing and resolving defects to maintain high software quality standards.",
    },
    {
      id: "6",
      title: "Performance and Security Testing",
      desc: "Conducting thorough performance and security tests to ensure software integrity and efficiency.",
    },
    {
      id: "7",
      title: "Documentation and Reporting",
      desc: "Maintaining detailed documentation and reporting for transparency and continuous quality monitoring.",
    },
    {
      id: "8",
      title: "Final Validation and Release",
      desc: "Executing final validation process for a quality-assured software release.",
    },
  ];
  return (
    <>
    <Helmet>
        <meta
          name="keywords"
          content="Quality engineering implementation service provider"
        />
      </Helmet>
      <CommonBannerImage backgroundImageURL="/assets/quality-engineering-bg1.WebP">
      <div className={styles.overlay}></div>
        <div className={styles.textContainer}>
          <h1>Quality Engineering</h1>
          <h6>Quality assurance that exceeds your expectations</h6>
        </div>
      </CommonBannerImage>
      <div>
        <Container>
          <Row>
            <Col md={6} className={styles.anlyseContainer}>
              <p>
                Clients come to us with their software products to address the
                challenges they face in making product improvements. Our answer
                is to restructure the application using quality testing and
                product prototyping for accurate results. By testing web apps,
                mobile apps, enterprise software, computer applications and
                anything digital across multiple runtime environments, we speed
                up the product lifecycle and development. Orbio Solutions has
                gained the trust of our customers by guaranteeing quality and
                security. The same quality engineering is applied to our own
                products, which are built with secure frameworks.
              </p>
            </Col>
            <Col
              md={6}
              className={`${styles.anlyseImgContainer} d-flex justify-content-center align-items-center`}
            >
              <Image
                alt="quality-engineering"
                src="/assets/quality-engineering-screen.WebP"
                fluid
                style={{ objectFit: "contain", height: "450px", width: "100%" }}
              />
            </Col>
          </Row>
        </Container>
      </div>

      <ServiceMainContent keyPoints={keyPoints} heading="How we do it:" />
    </>
  );
};

export default QualityEngineering;
