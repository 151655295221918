import React from "react";
import { Card, Col, Container, Image, Row } from "react-bootstrap";
import { BiSolidQuoteLeft } from "react-icons/bi";
import "./aboutCeoSection.css";

const AboutCeoSection = ()=> {
  return (
    <div className="aboutCeoMainContainer">
      <Container>
        <Card className="aboutCeoCard">
          <Row>
            <Col xs={12} md={4} className="fullWidthImageContainer" style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                <Image fluid src="/assets/rakesh4.WebP" alt="ceo image" className="aboutCeoImg"  />
            </Col>

            <Col xs={12} md={8}>
              <div className="ceocontentsection">
                <span>
                  <BiSolidQuoteLeft style={{ fontSize: "30px" }} />
                </span>
                <h3 className="mt-2">Rakeshnath Oopath</h3>
                <h6 className="mt-2">Founder & CEO</h6>
                <p className="mt-2">
                  In every challenge lies an opportunity. At Orbio Solutions, we
                  embrace innovation and promote a culture of commitment and
                  excellence. From turning an idea into a successful product to
                  offering end-to-end software solutions, my team and I focus on
                  delivering top-notch digital products and services that
                  accelerate your business and solve your customers’ needs. Our
                  mission is to help you achieve growth and success faster, and
                  better, in the safest and secure manner. As we strive to
                  inspire, innovate and exceed our customer expectations, we
                  thank our customers for being an integral part of our journey.
                </p>
              </div>
            </Col>
          </Row>
        </Card>
      </Container>
    </div>
  );
}

export default AboutCeoSection;
