import React from "react";
import CommonBannerImage from "../../components/CommonBannerImage";
import { Col, Container, Image, Row } from "react-bootstrap";
import styles from "./businessItSolutions.module.css";
import ServiceMainContent from "../../components/ServiceMainContent";
import { Helmet } from "react-helmet-async";

const BusinessItSolutions = () => {

  const keyPoints = [
    {
      id: "1",
      title: "Planning and Strategy",
      desc: "Strategizing and outlining the roadmap for tailored IT solutions.",
    },
    {
      id: "2",
      title: "Design and Coding",
      desc: "Crafting and programming functional and visually appealing software.",
    },
    {
      id: "3",
      title: "Performance Optimization",
      desc: "Enhancing software efficiency for optimal speed and reliability.",
    },
    {
      id: "4",
      title: "Security Implementation",
      desc: "Fortifying software with robust security measures.",
    },
    {
      id: "5",
      title: "Quality Assurance (QA) and Testing",
      desc: "Rigorous testing to ensure software reliability and quality.",
    },
    {
      id: "6",
      title: "Deployment",
      desc: "Launching the software from production to going live.",
    },
    {
      id: "7",
      title: "User Training and Documentation",
      desc: "Providing comprehensive training and detailed documentation for users.",
    },
    {
      id: "8",
      title: "Monitoring and Maintenance",
      desc: "Continuous supervision and upkeep to ensure optimal software performance.",
    },
    {
      id: "9",
      title: "Scalability and Future Enhancements",
      desc: "Planning for growth and future upgrades to maintain relevance and efficiency.",
    },
  ];
  
  let caseStudy = {
    id: "0",
    heading:
      "Building a Custom Application for Enhanced Efficiency and Security",
    desc: "Enhancing Data Management Efficiency and Effectiveness via Tailored Application Development.",
    img: "/assets/sdx-lap-screen.WebP",
    url: "/case-study/building-custom-application-for-enhanced-efficiency-and-security",
  };

  return (
    <>
    <Helmet>
        <meta
          name="keywords"
          content="Business IT solutions for companies of all sizes"
        />
      </Helmet>
      <CommonBannerImage backgroundImageURL="/assets/businessitsolution.WebP">
      <div className={styles.overlay}></div>
        <div className={styles.textContainer}>
          <h1>Business IT Solutions</h1>
          <h6>
            Excellence in Enterprise Software Solutions: Seamless Integration,
            Quality Testing, and Exceptional User Experience Design
          </h6>
        </div>
      </CommonBannerImage>
      <div>
        <Container>
          <Row>
            <Col
              md={6}
              className="d-flex justify-content-center align-items-center"
            >
              <Image
                alt="business-it-solutions"
                src="/assets/business-it-screen.WebP"
                fluid
                style={{ objectFit: "contain", height: "450px", width: "100%" }}
              />
            </Col>
            <Col md={6} className={styles.anlyseContainer}>
              <p>
                We seamlessly integrate software platforms like SAP and
                Salesforce and enhance business efficiency and connectivity. At
                each step of the product development cycle, our quality testing
                procedures ensure maximum security and functionality of
                software. By leveraging a vast network of technology
                partnerships and industry expertise, we design, develop, and
                deploy enterprise software’s that span diverse use case
                scenarios that include medical reporting, insurance data
                management, marketing automation, business process automation,
                customer relationship management, and employee experience
                platforms, to name some. Delivering exceptional user experience
                design is our forte and our success in enterprise application
                services are a testament to our carefully crafted go-to-market
                strategies.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
      <ServiceMainContent
        caseStudy={caseStudy}
        keyPoints={keyPoints}
        heading="The following are part of our process:"
      />
    </>
  );
};

export default BusinessItSolutions;
