import React from "react";
import CommonBannerImage from "../../components/CommonBannerImage";
import { Col, Container, Image, Row } from "react-bootstrap";
import styles from "./digitalSecurity.module.css";
import ServiceMainContent from "../../components/ServiceMainContent";
import { Helmet } from "react-helmet-async";

const DigitalSecurity = () => {
  const keyPoints = [
    {
      id: "1",
      title: "Risk Assessment and Analysis",
      desc: "Conducting comprehensive risk assessments to analyze and identify potential security vulnerabilities.",
    },
    {
      id: "2",
      title: "Security Policy Development",
      desc: "Developing robust security policies to provide a framework for safeguarding digital assets.",
    },
    {
      id: "3",
      title: "Security Architecture Design",
      desc: "Designing resilient security architecture to protect against evolving cyber threats.",
    },
    {
      id: "4",
      title: "Implementation of Security Measures",
      desc: "Implementing effective security measures to fortify enterprise digital assets.",
    },
    {
      id: "5",
      title: "Identity and Access Management",
      desc: "Managing identity and access to ensure secure and authorized usage of enterprise resources.",
    },
    {
      id: "6",
      title: "Incident Response Planning",
      desc: "Preparing strategic incident response plans to address and mitigate security breaches swiftly.",
    },
    {
      id: "7",
      title: "Data Backup and Recovery",
      desc: "Implementing reliable data backup and recovery strategies to safeguard critical information.",
    },
  ];
  return (
    <>
    <Helmet>
        <meta
          name="keywords"
          content="IT threat prevention and digital security services company"
        />
      </Helmet>
      <CommonBannerImage backgroundImageURL="/assets/digitalsecurity-bg.WebP">
      <div className={styles.overlay}></div>
        <div className={styles.textContainer}>
          <h1>Digital Security</h1>
          <h6>Protect your enterprise assets with robust security measures</h6>
        </div>
      </CommonBannerImage>
      <div>
        <Container>
          <Row>
            <Col md={6} className={styles.anlyseContainer}>
              <p>
                With a team of dedicated cyber security and risk mitigation
                experts, we help businesses secure their data and enterprise
                processes in the best possible manner. By leveraging leading
                tools and techniques for software governance, we provide key
                insights into system vulnerabilities and offer ways to maximize
                security against potential cyber threats. Digital security is
                fundamental to enterprise continuity, and our security best
                practices has saved millions of dollars for our clients and
                their customers.
              </p>
            </Col>
            <Col md={6} className={`${styles.anlyseImgContainer} d-flex justify-content-center align-items-center`}>
              <Image
                alt="digital-security"
                src="/assets/digital-security-screen.WebP"
                fluid
                style={{ objectFit: "contain", height: "450px", width: "100%" }}
              />
            </Col>
          </Row>
        </Container>
      </div>

      <ServiceMainContent keyPoints={keyPoints} heading="How we do it:" />
    </>
  );
};

export default DigitalSecurity;
