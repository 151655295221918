import React, { useEffect, useState } from 'react'
import { Route, Routes } from 'react-router-dom'
import AboutUs from './pages/AboutUs'
import ContactUs from './pages/ContactUs'
import ScrollToTop from './components/ScrollToTop'
import BlogPage from './pages/BlogPage'
import HomePage from './pages/HomePage'
import FutureofAi from './pages/FutureofAi'
import EcommerceBlog from './pages/EcommerceBlog'
import BigDataBlog from './pages/BigDataBlog'
import CompetitveBlog from './pages/CompetitveBlog'
import ProductPage from './pages/ProductPage'
import PrivacyPolicy from './pages/PrivacyPolicy'
import DataDrivenDecision from './pages/DataDrivenDecision'
import BusinessItSolutions from './pages/BusinessItSolutions'
import ProductEngineering from './pages/ProductEngineering'
import MobilityDigitalAcceleration from './pages/MobilityDigitalAcceleration'
import Careers from './pages/Careers'
import DynamicJobPage from './pages/DynamicJobPage'
import jobData from "../src/db/jobs.json";
import QualityEngineering from './pages/QualityEngineering'
import CloudManagedServices from './pages/CloudManagedServices'
import TechnologyConsulting from './pages/TechnologyConsulting'
import DigitalSecurity from './pages/DigitalSecurity'
import IotDevelopment from './pages/IotDevelopment'
import Education from './pages/Education'
import ErrorPage from './pages/ErrorPage'
import HealthCare from './pages/HealthCare'
import Retail from './pages/Retail'
import InfoGraphics from './pages/InfoGraphics'
import BusinessItCaseStudy from './pages/CaseStudy/BusinessItCaseStudy'
import ProductEngineeringCaseStudy from './pages/CaseStudy/ProcessEngineering'
import DataEngineeringCaseStudy from './pages/CaseStudy/DataEngineeringCaseStudy'
import AiSolutionCaseStudy from './pages/CaseStudy/AiSolutionCaseStudy'
import IotDevelpmentCaseStudy from './pages/CaseStudy/IotDevelpmentCaseStudy'
import ProtectedPage from './pages/ProtectedPage'
import RetailCaseStudy from './pages/CaseStudy/RetailCaseStudy'
import CookiesBanner from './components/CookiesBanner'
import CaseStudyDialog from './components/CaseStudyDialog'
// import ReactGA from "react-ga4";
import PricingStrategy from './pages/PricingStrategy'
import StrategicPricingIntelligence from './pages/StrategicPricingIntelligence'
import { Helmet } from 'react-helmet-async'
import RisingPriceManagement from './pages/RisingPriceManagement'
import EcommerceInsights from './pages/EcommerceInsights'
import TermsConditions from './pages/TermsConditions'
import HeadLessWooCommerce from './pages/HeadLessWooCommerce'
import GenerativeAi from './pages/GenerativeAi'
import DataArtificialIntelligence from './pages/DataArtificialIntelligence'
import DataAiSolution from './pages/DataAiSolution'
import Footer from './components/Footer'
import NavSection from './components/NavSection'

function App() { 

  // const location = useLocation()
  const [serviceCaseDialog,setServiceDialog] = useState(false)
  const [isFormSubmitted,setIsFormSubmitted] = useState(false)

  useEffect(() => {
    const storedFormSubmitted = localStorage.getItem("formSubmitted");
    setIsFormSubmitted(storedFormSubmitted);
  }, [serviceCaseDialog]);
  
  // useEffect(() => {
  //   ReactGA.initialize('code paste here...');
  // ReactGA.send({hitType: "pageview", page: location.pathname, title: "Google analytics"});
  // }, [location.pathname]);  

  const canonicalUrl = window.location.href;

  return (
    <div>
      <Helmet>
        <link rel="canonical" href={canonicalUrl} />
      </Helmet>
      <NavSection setServiceDialog={setServiceDialog} />
      {
      !isFormSubmitted && serviceCaseDialog && 
      (
        <CaseStudyDialog modalShow={serviceCaseDialog} setModalShow={setServiceDialog}
        modalNavigateUrl="/case-study/storee-feature-rich-e-portal-through-specialized-development-and-testing" />
      )
      }
      <ScrollToTop />
      <CookiesBanner />
      <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/about-us" element={<AboutUs />} />
      <Route path="/contact-us" element={<ContactUs />} />
      <Route path="/ottixhow" element={<ProductPage />} />
      <Route path="/careers" element={<Careers />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/terms-conditions" element={<TermsConditions />} />
      <Route path="/business-it-solutions" element={<BusinessItSolutions />} />
      <Route path="/product-engineering" element={<ProductEngineering />} />
      <Route path="/mobility-digital-acceleration" element={<MobilityDigitalAcceleration />} />
      <Route path="/generative-ai-service" element={<GenerativeAi />} />
      <Route path="/data-artificial-intelligence" element={<DataArtificialIntelligence />} />
      <Route path="/data-ai" element={<DataAiSolution />} />
      <Route path="/quality-engineering" element={<QualityEngineering />} />
      <Route path="/cloud-managed-services" element={<CloudManagedServices />} />
      <Route path="/technology-consulting" element={<TechnologyConsulting />} />
      <Route path="/digital-security" element={<DigitalSecurity />} />
      <Route path="/iot-development" element={<IotDevelopment />} />
      {jobData.map((job) => (
          <Route
            key={job.id}
            path={`/jobs/${job.designation}`}
            element={<DynamicJobPage job={job} />}
          />
        ))}
      <Route path="/blogs" element={<BlogPage />} />
      <Route path="/blog/future-of-ai-and-robotics" element={<FutureofAi />} />
      <Route path="/blog/things-to-be-aware-of-before-starting-an-ecommerce-business" element={<EcommerceBlog />} />
      <Route path="/blog/plan-your-project-with-your-software" element={<BigDataBlog />} />
      <Route path="/blog/competitive-data-analytics" element={<CompetitveBlog />} />
      <Route path="/blog/data-driven-decision-making" element={<DataDrivenDecision />} />
      <Route path="/blog/driving-sales-and-profits-why-your-retail-business-requires-a-well-constructed-pricing-strategy?" element={<PricingStrategy />} />
      <Route path="/blog/strategic-pricing-intelligence-enhancing-business-strategies-with-competitor-pricing-analytics" element={<StrategicPricingIntelligence />} />
      <Route path="/blog/the-rising-importance-of-price-management-and-optimization-for-online-retailers-of-today-and-tomorrow" element={<RisingPriceManagement />} />
      <Route path="/blog/e-commerce-insights-trends-challenges-winning-strategies" element={<EcommerceInsights />} />
      <Route path="/blog/headless-woocommerce" element={<HeadLessWooCommerce />} />
      <Route path="/education" element={<Education />} />
      <Route path="/health-care" element={<HealthCare />} />
      <Route path="/retail" element={<Retail />} />
      <Route path="/info-graphics" element={<InfoGraphics />} />
      
      <Route path="/case-study/building-custom-application-for-enhanced-efficiency-and-security" element={<ProtectedPage component={BusinessItCaseStudy} />} />
      <Route path="/case-study/process-engineering-in-the-legal-industry" element={<ProtectedPage component={ProductEngineeringCaseStudy} />} />
      <Route path="/case-study/data-analytics-for-eco-friendly-product-selection" element={<ProtectedPage component={DataEngineeringCaseStudy} />} />
      <Route path="/case-study/ottixHow" element={<ProtectedPage component={AiSolutionCaseStudy} />} />
      <Route path="/case-study/seamless-IoT-integration-in-existing-erp" element={<ProtectedPage component={IotDevelpmentCaseStudy} />} />
      <Route path="/case-study/storee-feature-rich-e-portal-through-specialized-development-and-testing" element={<ProtectedPage component={RetailCaseStudy} />} />

      <Route path="*" element={<ErrorPage />} />
      </Routes>
      <Footer />
    </div>
  )
}

export default App