import React from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import styles from "./aboutPageCards.module.css"

const AboutPageCards = ()=> {
  const cardData = [
    {
      id: "1",
      title: "INDUSTRY EXPERTISE",
      img:"/assets/industry.WebP",
      desc: "Our 100+ software specialists are recognized experts in their respective domains. With us your success is guaranteed because we supply the team that is best for your business, your industry vertical, and your needs.",
    },
    {
      id: "2",
      title: "TRUST",
      img:"/assets/trust.WebP",
      desc: "We’re your trusted software service provider. Our security and privacy policies not only protect your data, but also your company’s reputation.",
    },
    {
      id: "3",
      title: "INNOVATION",
      img:"/assets/innovation.WebP",
      desc: "At the forefront of innovation, our cutting-edge products and technologies are designed to empower you and your business, driving progress in the ever-evolving business landscape.",
    },
    {
      id: "4",
      title: "COMMITMENT & RELIABILITY",
      img:"/assets/commitment.WebP",
      desc: "We are strong believers of, ‘always under promise and over deliver’. We ensure we deliver what we promise, and if there’s a change, it’ll be for good. Quality and commitment are at the core of what we do.",
    },
  ]
  return (
    <div style={{marginTop:"4em"}} >
      <Container>
        <Row>
          {
            cardData.map((obj)=>(
              <Col md={3} key={obj.id}>
            <Card style={{ width: '100%',borderRadius:"0px",border:"none" }}>
                    <Card.Img
                      variant='top'
                      src={obj.img}
                      className={styles.aboutimg}
                      alt={obj.img}
                    />
              <Card.Body style={{paddingLeft:"0px"}} >
                <Card.Title className={styles.title} >{obj.title}</Card.Title>
                <Card.Text className={styles.desc} >
                  {obj.desc}
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
            ))
          }
        </Row>
      </Container>
    </div>
  );
}

export default AboutPageCards;
