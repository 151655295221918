import React, { useEffect, useState } from 'react'
import styles from "./serviceDrop.module.css"
import { Col, Container, Image, Row } from 'react-bootstrap'
import { NavLink, useNavigate } from 'react-router-dom'
import { BsDash } from "react-icons/bs";

const ServiceDropDown = ({setServiceDialog})=> {

  const navigate = useNavigate()
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  

  useEffect(() => {
    const storedFormSubmitted = localStorage.getItem("formSubmitted");
    setIsFormSubmitted(storedFormSubmitted);
  }, []);

  const handleOpenModal = () => {
    if (isFormSubmitted) {
      navigate('/case-study/storee-feature-rich-e-portal-through-specialized-development-and-testing');
      setServiceDialog(false);
    } else {
      setServiceDialog(true);
    }
  };

  return (
    <Container fluid>
            <Row>
            <div style={{
         background: "linear-gradient(180deg,rgba(0,0,0,.2),rgba(0,0,0,0))",
          height: "20px", position:"absolute", let:"0px", right:"0px", width:"100%"
        }}></div>
            </Row>
            <Row>
              {/* <Col xs={9} style={{minHeight: "410px"}}> */}
              <Col xs={10}>
                <Row>
                <Col xs={3} style={{background:"#eeeeee",padding:"20px",height:"100%",}} >
                    <div style={{textAlign:"start",marginTop:"30px"}} >
                    <h6 style={{fontSize:"14px",color:"#6f6f6f"}} >Elevate Every Customer Touchpoint</h6>
                    <hr style={{margin:"0px",width:"90%",marginTop:"15px"}} />
                    <p style={{fontSize:"14px",marginTop:"18px"}} >
                    We empower businesses to revolutionize customer experiences
                  across all channels, from online browsing to in-store
                  interactions. Our commitment to innovation, personalization
                  and efficiency fuels the development of comprehensive
                  omnichannel solutions, ensuring a seamless and delightful
                  journey for your customers at every turn.
                    </p>
                    </div>
                  </Col>
                  <Col xs={3} style={{padding:"20px"}} >
                  <div style={{textAlign:"start",marginTop:"30px"}} >
                    <h6 style={{fontSize:"14px",color:"#6f6f6f"}} >Digital Transformation & Automation</h6>
                    <hr style={{margin:"0px",width:"90%",marginTop:"15px"}} />
                    <div style={{display:"flex",flexDirection:"column",gap:"6px",marginTop:"18px"}} >
                      <NavLink to="/business-it-solutions" className={({ isActive }) =>`${ isActive ? `${styles.active}` : `${styles.notactive}` }`}>Business IT Solutions</NavLink>
                      <NavLink to="/product-engineering" className={({ isActive }) =>`${ isActive ? `${styles.active}` : `${styles.notactive}` }`}>Product Engineering</NavLink>
                      <NavLink to="/mobility-digital-acceleration" className={({ isActive }) =>`${ isActive ? `${styles.active}` : `${styles.notactive}` }`}>Mobility & Digital Acceleration</NavLink>
                      <NavLink to="/iot-development" className={({ isActive }) =>`${ isActive ? `${styles.active}` : `${styles.notactive}` }`}>IOT Development</NavLink>
                    </div>
                    </div>
                  </Col>
                  <Col xs={3} style={{padding:"20px"}} >
                  <div style={{textAlign:"start",marginTop:"30px"}} >
                    <h6 style={{fontSize:"14px",color:"#6f6f6f"}} >Data & AI</h6>
                    <hr style={{margin:"0px",width:"90%",marginTop:"15px"}} />
                    <div style={{display:"flex",flexDirection:"column",gap:"6px",marginTop:"18px"}} >
                    <NavLink to="/data-ai" className={({ isActive }) =>`${ isActive ? `${styles.active}` : `${styles.notactive}` }`}>Comprehensive AI & Data Solutions</NavLink>
                    <div>
                    <BsDash />
                    <NavLink to="/data-artificial-intelligence" className={({ isActive }) =>`${ isActive ? `${styles.active}` : `${styles.notactive}` }`}>Data & Artificial Intelligence</NavLink>
                    </div>
                    <div>
                    <BsDash />
                    <NavLink to="/generative-ai-service" className={({ isActive }) =>`${ isActive ? `${styles.active}` : `${styles.notactive}` }`}>Generative AI Service</NavLink>
                    </div>
                    </div>
                    </div>
                  </Col>
                  <Col xs={3} style={{padding:"20px"}} >
                  <div style={{textAlign:"start",marginTop:"30px"}} >
                    <h6 style={{fontSize:"14px",color:"#6f6f6f"}} >Digital Enablement</h6>
                    <hr style={{margin:"0px",width:"90%",marginTop:"15px"}} />
                    <div style={{display:"flex",flexDirection:"column",gap:"6px",marginTop:"18px"}} >
                    <NavLink to="/cloud-managed-services" className={({ isActive }) =>`${ isActive ? `${styles.active}` : `${styles.notactive}` }`}>Cloud Managed Services</NavLink>
                    <NavLink to="/technology-consulting" className={({ isActive }) =>`${ isActive ? `${styles.active}` : `${styles.notactive}` }`}>Technology Consulting</NavLink>
                    <NavLink to="/digital-security" className={({ isActive }) =>`${ isActive ? `${styles.active}` : `${styles.notactive}` }`}>Digital Security</NavLink>
                    <NavLink to="/quality-engineering" className={({ isActive }) =>`${ isActive ? `${styles.active}` : `${styles.notactive}` }`}>Quality Engineering</NavLink>
                    </div>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col xs={2} style={{background:"#eeeeee",padding:"20px",display:"flex",flexDirection:"column"}} >
                <h6 className="text-center">E-commerce</h6>
                <Image fluid src="/assets/storeeinservice2.WebP" alt="storee" style={{ objectFit:"contain",height:"auto" }} />
                <p style={{cursor:"pointer",fontSize:"14px",textAlign:"end",marginTop:"5px",color:"#007fc7"}} onClick={handleOpenModal} >Read Case Study</p>
              </Col>
            </Row>
           </Container>
  )
}

export default ServiceDropDown;