import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import styles from "./aboutSlider.module.css";

const AboutSlider = ()=> {
  return (
    <div>
      <Container fluid>
        <Row>
          <Col md={6} className="p-0">
            <div className={`${styles.stickyContainerLeft}`}>
              <div
                style={{
                  height: "100vh",
                  backgroundImage: `url('/assets/digitalTransformation.WebP')`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  position: "sticky",
                  top: 0,
                }}
              >
                <div className={styles.stickyShadow}></div>
                <div
                  className={styles.firstimgContent}
                >
                  <div style={{ padding: "20px" }}>
                    <h2>DIGITAL TRANSFORMATION & AUTOMATION</h2>
                    <p style={{ fontStyle: "italic" }}>
                      In-Depth Technology Consulting: Elevating Businesses
                      through Digital Transformation
                    </p>
                    <p>
                      Embark on a transformative journey with Orbio, where we
                      redefine the landscape of digital transformation and
                      automation. Our comprehensive technology consulting
                      services cover Cloud Managed Services, Software Product
                      Engineering, IoT, Quality Engineering, Enterprise Apps,
                      and IT Governance.
                    </p>
                    <p>
                      At Orbio, we meticulously design and implement cloud
                      solutions that transcend industry standards, ensuring your
                      digital infrastructure operates at peak performance. In
                      the realm of Software Product Engineering, our seasoned
                      team guides you from product design through development,
                      testing, and deployment, exceeding end-user expectations
                      with a commitment to excellence. In addition, we stand as
                      pioneers in IoT, crafting ingenious solutions that
                      seamlessly integrate devices, data, and analytics,
                      unlocking the full potential of the Internet of Things.
                      Trust us to lead your organization into a connected
                      future, where efficiency and intelligence converge
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col md={6} className="p-0">
            <div className={styles.rightSideImageContainer}>
              <div className={styles.imageWithShadow}>
                <LazyLoadImage
                  effect="blur"
                  src="/assets/enterpriseitsolutions.WebP"
                  alt="Enterprise IT Solutions"
                  className={`${styles.rightSideImage}`}
                />
                <div className={styles.centeredText}>
                  <div>
                    <h2>BUSINESS IT SOLUTIONS</h2>
                    <p style={{ fontStyle: "italic" }}>
                      Strategic Planning to Execution: Unleashing Advanced
                      Business IT Solutions for Future-Ready Enterprises
                    </p>
                    <p>
                      At Orbio, we seamlessly guide enterprises through the
                      intricate path from strategic planning to flawless
                      execution, delivering cutting-edge Business IT Solutions
                      steeped in technological prowess. Our relentless focus on
                      digital enablement and business agility empowers clients
                      to not only unlock immediate but sustained business value
                      right from the outset.
                    </p>
                    <p>
                      With a strategic approach tailored to future-ready
                      enterprises, Orbio ensures that businesses harness the
                      full potential of technology in their operations. Our
                      expertise lies in crafting bespoke solutions that not only
                      meet but exceed the evolving demands of the digital
                      landscape. Trust us to navigate your organization towards
                      a future where technological innovation is seamlessly
                      woven into the fabric of your business strategy.
                    </p>
                  </div>
                </div>
              </div>
              <div className={styles.imageWithShadow}>
                <LazyLoadImage
                  effect="blur"
                  src="/assets/productengineering.WebP"
                  alt="Product Engineering"
                  className={`${styles.rightSideImage}`}
                />
                <div className={styles.centeredText}>
                  <div>
                    <h2>PRODUCT ENGINEERING</h2>
                    <p style={{ fontStyle: "italic" }}>
                      Engineering Excellence: Fulfilling and Surpassing End-User
                      Expectations
                    </p>
                    <p>
                      Experience a comprehensive and meticulous approach to
                      Product Engineering with Orbio. From the inception of
                      product design through the intricacies of development,
                      rigorous testing, and deployment, our commitment extends
                      far beyond meeting expectations - it aims to surpass them.
                      Rooted in trust, our software products not only embody
                      excellence in product engineering but also draw from a
                      wealth of industry experience to deliver a level of
                      quality assurance that exceeds industry benchmarks.
                    </p>
                    <p>
                      At Orbio, we understand that engineering excellence is not
                      just a goal; it's a commitment to a seamless and
                      innovative end-user experience. Our team is dedicated to
                      the relentless pursuit of perfection, ensuring that your
                      software products not only meet but surpass the
                      expectations of your end-users, fostering lasting
                      satisfaction and loyalty
                    </p>
                  </div>
                </div>
              </div>
              <div className={styles.imageWithShadow}>
                <LazyLoadImage
                  effect="blur"
                  src="/assets/mobilityAcceleration9.WebP"
                  alt="Mobility Digital Acceleration"
                  className={`${styles.rightSideImage}`}
                />
                <div className={styles.centeredText}>
                  <div>
                    <h2>MOBILITY & DIGITAL ACCELERATION</h2>
                    <p style={{ fontStyle: "italic" }}>
                      Pioneering Experience Design: Crafting Immersive and
                      Technologically Advanced Digital Experiences
                    </p>
                    <p>
                      As pioneers in experience design, Orbio stands at the
                      forefront, offering a spectrum of services that transcend
                      the ordinary. Our expertise spans UI/UX, digital
                      marketing, eCommerce, and mobility solutions, ensuring a
                      holistic approach to crafting seamless and engaging
                      digital experiences for your audience. From the creation
                      of sleek apps to the development of responsive interfaces,
                      we take pride in our ability to create not just digital
                      solutions but immersive experiences that resonate with
                      your audience.
                    </p>
                    <p>
                      Trust Orbio to accelerate your digital presence with a
                      unique blend of innovation, finesse, and technological
                      advancement. Our commitment to pioneering experience
                      design ensures that your digital footprint becomes a
                      testament to your brand's commitment to excellence and
                      user-centricity in the ever-evolving digital landscape.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default AboutSlider;
