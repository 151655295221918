import React from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'
import styles from "./aboutHelpBusinesses.module.css"

const AboutHelpBusinesses = () => {
  return (
    <div style={{marginTop:"3em"}} >
        <Container>
            <Row>
                <Col xs={12}>
                    <h4 className={styles.heading} >How we help businesses?</h4>
                </Col>
            </Row>
            <Row className={styles.aligncenter} >
            <Col md={6} >
                <ul className={styles.mainlist} >
                    <li>We synergize access to technology with strategic implementation of digital solutions</li>
                    <li>We professionally cultivate reliable solutions to business-centric challenges</li>
                    <li>We diversify the use of technology across verticals for business growth</li>
                    <li>We study customers and employee experiences for intuitive product development</li>
                    <li>We bridge the gap left behind by legacy processes for business process efficiency</li>
                    <li>We leverage big data and analytics to maximize value of data-driven decisions</li>
                    <li>We plan and execute digital initiatives and web services to improve business visibility and market penetration</li>
                </ul>
            </Col>
            <Col md={6} >
                <Image fluid src='/assets/help-business.WebP' alt='business' className={styles.img} />
            </Col>
            </Row>
        </Container>
    </div>
  )
}

export default AboutHelpBusinesses