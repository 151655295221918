import React from "react";
import AboutOverviewSection from "../../components/AboutOverviewSection";
import AboutPageCards from "../../components/AboutPageCards";
import AboutPageClients from "../../components/AboutPageClients";
import AboutCeoSection from "../../components/AboutCeoSection";
import AboutHelpBusinesses from "../../components/AboutHelpBusinesses";
import CommonBannerImage from "../../components/CommonBannerImage";
import styles from "./aboutus.module.css";
import { Col, Container, Row } from "react-bootstrap";
import { Helmet } from "react-helmet-async";

function AboutUs() {

  return (
    <>
    <Helmet>
        <meta
          name="keywords"
          content="Enterprise software services and digital transformation solution provider for businesses of all sizes"
        />
      </Helmet>
      <CommonBannerImage
        className={styles.CommonBannerImage}
        backgroundImageURL="/assets/about-background4.WebP"
      >
        <div className={styles.overlay}></div>
        <div className={styles.textContainer}>
          <h1>About us</h1>
          <h6>
            Orbio Solutions empowers businesses, giving them the products and
            services they need to implement, scale, and optimize for continued
            business growth. We are a one-stop-shop for companies of all sizes
            in delivering comprehensive digital transformation and automation,
            data analytics, and digital enablement.
          </h6>
        </div>
      </CommonBannerImage>
      <AboutOverviewSection />
      <AboutCeoSection />
      <AboutHelpBusinesses />
      <AboutPageCards />
      <AboutPageClients />
      <div className="mt-5">
        <Container>
          <Row>
            <Col className={styles.orbioContent}>
              <h3 className={`mb-4`}>The Orbio Way</h3>
              <p>
                The Orbio Way acts as a helpful guide for individuals with
                ambitious ideas who aspire to bring them to life. We're not just
                tech experts; we serve as enthusiastic partners, dedicated to
                transforming your innovative concepts into tangible
                achievements.
              </p>
              <p>
                Why opt for Orbio? Because we grasp and utilize the language of
                innovation.
              </p>
              <p>
                Our strength lies in developing cutting-edge software and
                leveraging AI and data analytics. Picture us as imaginative
                scientists crafting the ideal strategy for your success. At
                Orbio, we advocate for a personalized approach your ideas, our
                expertise. Rejecting the one size fits all mindset, we invest
                time in understanding what makes your vision unique. Our
                solutions, much like our tech capabilities, are tailored
                specifically for you. It's not merely about advanced technology;
                it's about delivering concrete outcomes. We assist you in
                translating your remarkable ideas into real-world applications,
                whether it's improving functionality, maximizing data
                utilization, or creating user-friendly products. Trust is
                paramount to us, and we ensure the safety of your ideas in our
                hands. We build trust gradually, ensuring your ideas are well
                taken care of. We're not interested in quick jobs; we're
                committed for the long run. We value ongoing collaboration,
                working with you to enhance and sustain your solutions for
                long-term success. The Orbio Way transcends mere service
                provision; it embodies a mindset. It revolves around maximizing
                the potential of your ideas, kindling the flame of innovation,
                and guiding you from ideation to execution. So, are you ready to
                take the plunge? Join us on The Orbio Way, and let's transform
                your significant dreams into tangible accomplishments.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default AboutUs;
